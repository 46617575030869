/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	isPopupVisible: false,
})

export default function useChangeReminderDueDateStore() {
	return {
		...toRefs(state),
	}
}
