<!-- @format -->

<template>
	<transition name="modal-fade">
		<div>
			<div>{{ reminderData }}</div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle">
						<slot name="header"></slot>
					</header>

					<section class="modal-body" id="modalDescription">
						<b-button
							type="button"
							class="is-danger"
							@click="onCloseModal"
							aria-label="Close modal"
							style="width: 250px"
						>
							X Close
						</b-button>
						<slot name="body"></slot>
						<div style="margin-top: 30px">
							<p>
								<span class="tag-style">Customer: </span
								>{{ reminderData.customer }}
							</p>
							<p>
								<span class="tag-style">Invoice Number: </span
								>{{ reminderData.invoiceNumber }}
							</p>
							<p>
								<span class="tag-style">Invoice Date: </span
								>{{ reminderData.invoiceDate }}
							</p>
							<p>
								<span class="tag-style">Invoice Due Date: </span
								>{{ reminderData.invoiceDueDate }}
							</p>
							<p>
								<span class="tag-style">Reminder Number: </span
								>{{ reminderData.reminderNumber }}
							</p>
							<p>
								<span class="tag-style">Invoice Date: </span
								>{{ reminderData.reminderDate }}
							</p>
							<p>
								<span class="tag-style">Invoice Due Date: </span
								>{{ reminderData.reminderDueDate }}
							</p>
						</div>
						<div>
							<b-field class="margin-top-20" label="Select new due date">
								<b-datepicker
									v-model="selectedDueDate"
									:show-week-number="showWeekNumber"
									:locale="locale"
									placeholder="Click to select..."
									icon="calendar-today"
									trap-focus
								>
								</b-datepicker>
							</b-field>
						</div>
						<div>
							<b-button
								class="is-success"
								style="margin-top: 30px"
								@click="changeDueDate"
							>
								Change Due Date
							</b-button>
						</div>
					</section>
					<footer class="modal-footer">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import useChangeReminderDueDateStore from '@/_srcv2/pages/admin/super-privileges/change-reminder-due-date/useChangeReminderDueDateStore.js'
import { onMounted, reactive } from '@vue/composition-api'
import ChangeReminderDueDateMutation from '@/_srcv2/pages/admin/super-privileges/change-reminder-due-date/ChangeReminderDueDateMutation.graphql'
import { useMutation } from '@vue/apollo-composable'
// import Router from '@/router'
import Store from '@/store'
import { ref, watchEffect } from '@vue/composition-api'
import dateformat from 'dateformat'

export default {
	name: 'ModalReminderFrame',
	props: {
		data: {
			type: Object,
			required: true,
		},
		handler: {
			type: Function,
		},
	},
	setup(props) {
		const reminderData = props.data
		const today = ref(new Date())
		const showWeekNumber = false
		const locale = 'sv-SE'
		const selectedDueDate = ref(new Date(props.data.reminderDueDate))
		watchEffect(() => {
			mutationVariables.reminder_due_date = dateformat(
				selectedDueDate.value,
				'yyyy-mm-dd',
			)
			console.log('mutationVariables', mutationVariables)
			console.log('selectedDueDate', selectedDueDate.value)
		})
		const { isPopupVisible } = useChangeReminderDueDateStore()
		const onCloseModal = () => {
			isPopupVisible.value = false
			props.handler()
			// Router.push({ name: 'ChangeInvoiceDueDate' })
		}
		const mutationVariables = reactive({
			company_nickname: Store.getters.getUserCurrentCompany,
			reminder_id: props.data.reminderNumber,
			reminder_due_date: props.data.reminderDueDate,
		})
		const {
			mutate: changeReminderDueDate,
			onError,
			onDone,
		} = useMutation(ChangeReminderDueDateMutation, () => ({
			variables: mutationVariables,
		}))
		const changeDueDate = () => {
			changeReminderDueDate()
		}
		onError((err) => alert('Error: ', err))
		onDone(() => onCloseModal())
		// --------------------------------------------------------------------------------
		onMounted(() => {})
		// ---------------------------------------------------------------------------
		return {
			onCloseModal,
			isPopupVisible,
			reminderData,
			changeDueDate,
			selectedDueDate,
			today,
			showWeekNumber,
			locale,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}
.modal {
	background-color: rgb(76, 92, 122);
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 780px;
	max-height: 100%;
	height: 100%;
	margin: auto;
	padding: 20px;
	opacity: 1;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}
.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}
.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}
.modal-body {
	position: relative;
	padding: 20px 10px;
}
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
.tag-style {
	font-weight: 700;
	color: blue;
}
p {
	margin-bottom: 10px;
}
</style>
